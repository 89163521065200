<template>
	<form class="form-horizontal" @submit.prevent="save">
		<div class="animated fadeIn">
			<b-card class="min-vh-100">
				<div class="display-table full-width mb-2">
					<strong class="card-title">{{$route.meta.label}}</strong>
				</div>
				<b-row>
					<b-col md="3" class="mb-2">
						<div class="image-input">
							<b-img :src="getUrlOfFile(models.thumbnail)" class="mb-2" />
              <b-link class="btn-upload btn-block d-block mb-4" :href="models.thumbnail" target="_blank">
                View Thumbnail
              </b-link>
						</div>
					</b-col>
					<b-col md="6">
						<div v-if="models.pdf" class="text-center mb-3">
							<a :href="models.pdf" target="_blank" class="d-block mb-3">
								<img src="/img/pdf-icon.png" alt="pdf icon" width="100">
							</a>

							<span>{{models.fileName}}</span>
						</div>
            <b-link class="btn btn-outline-primary d-block mb-4" :href="models.pdf" target="_blank">
              View PDF
            </b-link>
						<div class="mb-4">
							<h6>Title</h6>
              <span>{{models.title}}</span>
						</div>
						<div class="mb-4">
							<h6>Date</h6>
              <span>{{moment(models.date).format('DD MM YYYY HH:mm')}}</span>
						</div>
            <div class="flex flex-row mb-4">
              <h6>Status:</h6>
              <span :class="'badge badge-' + getClassStatus(models.status)" class="text-capitalize">
                {{models.status}}
              </span>
            </div>
						<div class="d-flex justify-content-center">
							<b-button type="button" variant="light" class="btn-min-width rounded px-5" block @click="$router.push({name: config.uri})">
								Cancel
							</b-button>
						</div>
					</b-col>
				</b-row>
			</b-card>
		</div>
	</form>
</template>
<script>
	import moment from 'moment'
	import api from '@/utils/api.js'
	import {
		getUrlOfFile,
		getFilenameFromUrl
	} from '@/utils/helpers.js';
	import { getClassStatus } from '@/utils/helpers';


	export default {
		name: 'DetailEpaper',
		data() {
			return {
				config: {
					uri: this.$route.meta.permission.link,
				},
				models: {
					title: '',
					date: '',
					thumbnail: '',
					pdf: '',
					status: 'publish',
					fileName: ''
				},
				moment
			}
		},
		created() {
			let _ = this
			_.getById();
		},
		methods: {
			getById() {
				const _ = this;
				if (_.$route.params.id) {
					_.$axios.get(`${api.epaper}/${_.$route.params.id}`)
						.then(res => {
							const { data } = res.data;
							_.models.title = data.title
							_.models.date = moment(data.date).format('YYYY-MM-DDTHH:mm')
							_.models.thumbnail = data.thumbnail || ''
							_.models.pdf = data.pdf
							_.models.status = data.status

							_.models.fileName = getFilenameFromUrl(data.pdf)
						}).catch(() => _.$router.push({
							name: 'page404'
						}));
				}
			},
      getClassStatus(status) {
				return getClassStatus(status);
			},
			getUrlOfFile(file) {
				return getUrlOfFile(file, true);
			}
		}
	}
</script>